import { useState } from "react";
import firstCloud from './assets/clouds/cloud-1.svg'
import secondCloud from './assets/clouds/cloud-2.svg'

const getCloud = () => {
  const images = [
    secondCloud,
    secondCloud,
    firstCloud,
    firstCloud,
    secondCloud,
    firstCloud,
    secondCloud,
    secondCloud,
    firstCloud,
    firstCloud,
    secondCloud,
  ];

  return images[Math.floor(Math.random() * 11)];
};

const getDistance = () => Math.floor(Math.random() * 1000 - 20);

const createCloud = (prev_cloud = { x: 0, w: 0 }) => {
  const h = 300;
  const w = 600;
  const x = prev_cloud.x + prev_cloud.w + getDistance();
  const y = Math.floor(Math.random() * (100 - 50 + 1)) + 50;
  return {
    h: h,
    w: w,
    x: x,
    y: y,
    cloud: getCloud(),
  };
};

const useCloud = () => {
  const [clouds, setClouds] = useState([createCloud()]);
  const addCloud = () =>
    setClouds([...clouds, createCloud(clouds[clouds.length - 1])]);
  const destroyCloud = () => {
    const temp = [...clouds];
    temp.splice(0, 1);
    setClouds(temp);
  };

  const resetCloud = () => setClouds([createCloud()]);

  return { clouds, addCloud, destroyCloud, resetCloud };
};

export default useCloud;
