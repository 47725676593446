import { useEffect } from "react";
import useBuilding from "../Engine/useBuilding";
import useCloud from "../Engine/useCloud";
import Building from "./Building";
import Cloud from "./Cloud";

const Background = ({ player_x }) => {
  const { buildings, addBuilding, destroyBuilding, reset } = useBuilding();
  const { clouds, addCloud, destroyCloud, resetCloud } = useCloud();

  useEffect(() => {
    const body_w = document.body.clientWidth;
    if (buildings[buildings.length - 1].x - player_x < body_w + 100)
      addBuilding();
    if (buildings[0].x + 2500 < player_x) destroyBuilding();
    if (buildings[0].x > 30 && player_x === 0) reset();

    if (clouds[clouds.length - 1].x - player_x / 2 < body_w + 100) addCloud();
    if (clouds[0].x + 700 < player_x / 2) destroyCloud();
    if (clouds[0].x > 30 && player_x === 0) resetCloud();
  });
  return (
    <>
      <div className="buildings">
        {buildings.map((building, key) => {
          return <Building {...building} x={building.x - player_x} key={key} />;
        })}
      </div>

      <div className="clouds">
        {clouds.map((cloud, key) => {
          return (
            <Cloud
              {...cloud}
              x={cloud.x - player_x / 2}
              w={600}
              h={300}
              key={key}
            />
          );
        })}
      </div>
    </>
  );
};

export default Background;
