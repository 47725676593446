import { useBonusesStore } from "../../store";
import { useEffect, useState } from 'react';
import './Final.css';
import { useNavigate } from 'react-router-dom';

function Final() {
  const points = useBonusesStore((state) => state.points);
  const cashBackType = useBonusesStore((state) => state.cashback_type);
  const spentSeconds = useBonusesStore((state) => state.spent_seconds);
  const [pointsText, setPointsText] = useState('');
  const navigate = useNavigate();
  const preventDefaultFunction = (e) => e.preventDefault();

  const sendReult = async () => {
    const setInSessinStorage = () => {
      const results = sessionStorage.results
        ? JSON.parse(sessionStorage.results)
        : [];

      results.push({
        cashback_type: cashBackType,
        points: points,
        spent_seconds: spentSeconds,
      });

      sessionStorage.setItem('results', JSON.stringify(results));
    };

    if (navigator.onLine) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACK_ENDPOINT}/game-sessions`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              cashback_type: cashBackType,
              points: points,
              spent_seconds: spentSeconds,
            }),
          }
        );
        const reponseJSON = await response.json();
        return reponseJSON;
      } catch (error) {
        setInSessinStorage();
        console.log('error ', error);
      }
    }

    if (!navigator.onLine) {
      setInSessinStorage();
      return true;
    }
  };

  const redirectTiMainPage = () => {
    if (cashBackType !== '' && spentSeconds !== 0) {
      const response = sendReult();
      if (!response.errors) {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    if (points) {
      if (points === 1) {
        setPointsText('балл');
      }

      if (points >= 2 && points <= 4) {
        setPointsText('балла');
      }

      if (points > 4) {
        setPointsText('баллов');
      }
    }

    document.addEventListener('contextmenu', preventDefaultFunction);

    return () => {
      document.removeEventListener('contextmenu', preventDefaultFunction);
    };
  }, [cashBackType, points, spentSeconds]);

  return (
    <div className="wrapper">
      <div className="final__items__wrapper">
        <img className="main__logo" src="/logos/main-logo.svg" alt="Т-лого" />
        <h1 className="main__title">Вы набрали</h1>
        <p className="points__value">{points}</p>
        <span className="main__title">{pointsText}</span>
        <button
          className="button__link button__link_active"
          onClick={redirectTiMainPage}
        >
          В начало
        </button>
      </div>
    </div>
  );
}

export default Final;
