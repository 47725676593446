import "./BonusItem.css";
import { Tooltip } from 'react-tooltip';

export default function BonusItem({ src, title, description, click }) {
  return (
    <div className="bonus__item" onClick={click}>
      <div className="image__wrapper">
        <img className="category__image" style={{ width: '300px', height: '300px' }} src={src} alt="Бонус" />
      </div>

      <div>
        <h3 className="bonus__title">{title}</h3>
        {title === 'Подписка PRO' ?
          <div className="bonus__description__block">
            <p className="bonus__description">{description}</p>
            <img id="anchor" src="/logos/question-mark.svg" alt="Иконка вопроса" />
            <Tooltip anchorSelect="#anchor" openOnClick place="top-start" style={{ borderRadius: '16px', width: '380px' }}>
              <p className="tooltip__description">На 2 месяца для новых клиентов, на 1 месяц для действующих клиентов</p>
            </Tooltip>  
        </div> :
        <p className="bonus__description">{description}</p>        
        }
      </div>
    </div>
  );
}
