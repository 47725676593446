const createBonus = (x, y, w, h, isFake) => {
  return {
    x,
    y,
    w,
    h,
    isFake: isFake,
  };
};
export default createBonus;
