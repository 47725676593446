import { useEffect, useState } from "react";
import { useBonusesStore } from "../store";
import cashbackData from "../cashback.json";

const InfoBar = ({ player, game }) => {
  const [seconds, setSeconds] = useState(30);
  const [bonusImage, setBonusImage] = useState(null);
  const cashbackType = useBonusesStore((state) => state.cashback_type);
  const setSpentSeconds = useBonusesStore((state) => state.setSpentSeconds);

  useEffect(() => {
    if (cashbackType !== null) {
      const filteredBonuses = cashbackData.filter(
        (bonus) => bonus.type === cashbackType
      );
      if (filteredBonuses.length > 0) {
        setBonusImage(filteredBonuses[0].image_selected);
      }
    }

    if (player.isRunning > 0) {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);

      setSpentSeconds(30 - seconds);
      return () => clearInterval(intervalId);
    }
  }, [cashbackType, player.isRunning, seconds, setSpentSeconds]);

  return (
    <div className="infobar">
      <div className="score">
        <img className="bar__bonus__image" src={bonusImage} alt="Бонус" />
        <p>{game.score}</p>
      </div>

      <div className="lifes">
        <img className="life__image" src="/logos/heart.svg" alt="Бонус" />
        <p>{player.life + 1}</p>
      </div>

      <div className="time">
        <p>{`00:${seconds < 10 ? `0${seconds}` : seconds}`}</p>
      </div>
    </div>
  );
};

export default InfoBar;
