const Cloud = ({ x, y, h, w, cloud }) => {
  return (
    <div
      className="cloud"
      style={{
        height: h,
        width: w,
        left: 0,
        transform: `translate3d(${x}px, 0, 0)`,
        top: y,
        position: 'absolute',
      }}
    >
      <img src={cloud} width={w} height={h} alt="Облако" />
    </div>
  );
};

export default Cloud;
