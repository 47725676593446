const getRandomIndexes = (from, to, n) => {
  const result = [...Array(to - from + 1).keys()]
    .map((index) => index + from)
    .reduce((array, elt) => {
      array.splice(Math.random() * (array.length + 1), 0, elt);
      return array;
    }, [])
    .slice(0, n);

  return result;
};

export default getRandomIndexes;
