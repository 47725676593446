import { useState } from "react";

const useGame = () => {
  const [isEnd, setEnd] = useState(false);
  const [collectingBonus, setCollectingBonus] = useState(false);
  const [score, setScore] = useState(0);
  const [hiddenBonusIndex, setHiddenBonusIndex] = useState(null);
  const minObstacleY = 130;
  const minBonusY = 150;

  const listen = (player, obstacles, resetObs, bonuses, resetBonuses) => {
    if (player.immune || collectingBonus || score > 20) return;

    const lossOfLife = () => {
      if (player.life > 0) {
        // console.log("hit");
        player.hit();
      } else {
        player.stopRun();
        setEnd(true);
        resetBonuses();
        resetObs();
        player.restart();
      }
    };

    const isPlayerHit = (minY, item) => {
      if (item.y > minY) {
        // объект сверху
        return (
          player.x + player.width > item.x &&
          player.x < item.x + item.w &&
          player.y + player.height >= item.y
        );
      } else {
        return (
          player.x + player.width > item.x &&
          player.x < item.x + item.w &&
          player.y <= item.h
        );
      }
    };

    const obstacleHit = obstacles.some((obs) => isPlayerHit(minObstacleY, obs));

    let bonusCollected = false;
    let fakeBonusCollected = false;
    const index = bonuses.findIndex((bonus) => isPlayerHit(minBonusY, bonus));

    if (index !== -1) {
      setHiddenBonusIndex(index);
      bonusCollected = !bonuses[index].isFake;
      fakeBonusCollected = bonuses[index].isFake;
    }

    if (bonusCollected) {
      setScore((prevScore) => prevScore + 1);
      setCollectingBonus(true);
      setTimeout(() => {
        setCollectingBonus(false);
      }, 600);
    }

    if (fakeBonusCollected || obstacleHit) lossOfLife();
  };

  return { isEnd, score, listen, hiddenBonusIndex };
};

export default useGame;
