import { useState } from "react";
import createBonus from "./createBonus";
import getRandomIndexes from './function/getRandomIndexes';

const useBonus = () => {
  const bonusPositions = [
    1000, 3100, 5000, 6800, 7700, 9500, 11400, 13200, 14100, 15800, 17700,
    19300, 21900, 23600, 25300, 27100, 28700, 31000,
  ];

  const fakeBonuses = getRandomIndexes(4, 12, 3);

  const [bonuses, setBonuses] = useState(
    bonusPositions.map((pos, index) =>
      createBonus(
        pos,
        Math.round(Math.random()) ? 150 : 450,
        60,
        60,
        fakeBonuses.includes(index)
      )
    )
  );
  const addBonus = () => {
    const distance = Math.floor(Math.random() * 800 + 500);
    const height = Math.floor(Math.random() * 50 + 30);
    const width = Math.floor(Math.random() * 70 + 20);
    const bon = [...bonuses];
    if (bon.length > 9) return;
    bon.push(
      createBonus(bonuses[bonuses.length - 1].x + distance, width, height)
    );
    setBonuses(bon);
  };

  const hideBonus = (index) => {
    const updatedBonuses = [...bonuses];
    updatedBonuses[index].visible = false;
    setBonuses(updatedBonuses);
  };

  const resetBonus = () =>
    setBonuses(
      [
        1000, 3100, 5000, 6800, 7700, 9500, 11400, 13200, 14100, 15800, 17700,
        19300, 21900, 23600, 25300, 27100, 28700, 31000,
      ].map((pos) => createBonus(pos, 60, 60))
    );
  return [bonuses, addBonus, resetBonus, hideBonus];
};

export default useBonus;
