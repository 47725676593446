import { useState } from "react";
import gasStation from './assets/buildings/gas-station.svg';
import shop from './assets/buildings/shop.svg';
import theater from './assets/buildings/theater.svg';

const getBuilding = () => {
  const images = [
    gasStation,
    shop,
    theater,
  ];

  return images[Math.floor(Math.random() * 3)];
};

const getDistance = () => Math.floor(Math.random() * 1500 - 20);

const createBuilding = (prev_building = { x: 0, w: 0 }) => {
  const h = 694;
  const w = 2440;
  const x = prev_building.x + prev_building.w + getDistance();
  return {
    h: h,
    w: w,
    x: x,
    building: getBuilding(),
  };
};

const useBuilding = () => {
  const [buildings, setBuilding] = useState([createBuilding()]);
  const addBuilding = () =>
    setBuilding([
      ...buildings,
      createBuilding(buildings[buildings.length - 1]),
    ]);
  const destroyBuilding = () => {
    const temp = [...buildings];
    temp.splice(0, 1);
    setBuilding(temp);
  };

  const reset = () => setBuilding([createBuilding()]);

  return { buildings, addBuilding, destroyBuilding, reset };
};

export default useBuilding;
