import { useState } from "react";
import createObstacle from "./createObstacle";

const useObstacle = () => {
  const [obstacles, setObs] = useState(
    [
      2100, 4000, 5900, 8600, 10450, 12300, 15000, 16750, 18600, 20100, 22750,
      24300, 26200, 27900, 30100, 33700, 35600,
    ].map((pos) => {
      return createObstacle(
        pos,
        Math.round(Math.random()) ? 130 : 380, //либо выше объект, либо пониже
        180,
        80
      );
    })
  );
  const addObstacle = () => {
    const distance = Math.floor(Math.random() * 800 + 800);
    const obs = [...obstacles];
    // const height = 90
    // const width = 200
    if (obs.length > 6) obs.splice(0, 1);
    obs.push(createObstacle(obstacles[obstacles.length - 1].x + distance));
    setObs(obs);
  };
  const resetObs = () =>
    setObs(
      [
        2100, 4000, 5900, 8600, 10450, 12300, 15000, 16750, 18600, 20100, 22750,
        24300, 26200, 27900, 30100, 33700, 35600,
      ].map((pos) => createObstacle(pos, 180, 80))
    );
  return [obstacles, addObstacle, resetObs];
};

export default useObstacle;
