const Building = ({ x, h, w, building }) => {
  return (
    <div
      className="building"
      style={{
        height: h,
        width: w,
        left: 0,
        transform: `translate3d(${x}px, 0, 0)`,
      }}
    >
      <img src={building} width={w} height={h} alt="Здание" />
    </div>
  );
};

export default Building;
