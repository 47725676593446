import { useEffect } from "react";
import "./GamePage.css";
import useGame from "../../Engine/useGame";
import usePlayer from "../../Engine/usePlayer";
import useObstacle from "../../Engine/useObtacle";
import useBonus from "../../Engine/useBonus";
import InfoBar from "../../View/InfoBar";
import Background from "../../View/Background";
import { useState } from "react";
import { useBonusesStore } from "../../store";
import cashbackData from "../../cashback.json";
import { useNavigate } from "react-router-dom";
import Button from '../../View/Button';
import getRandomIndexes from '../../Engine/function/getRandomIndexes';
import Lottie from 'react-lottie-player';
import BirdJson from '../../Bird.json';

function App() {
  const player = usePlayer(250, 500, 320);
  const [obstacles, addObstacle, resetObs] = useObstacle();
  const [bonuses, addBonus, resetBonus] = useBonus();
  const [bonusImage, setBonusImage] = useState(null);
  const [fakeBonusImages, setFakeBonusImages] = useState([]);
  const preventDefaultFunction = (e) => e.preventDefault();
  const cashbackType = useBonusesStore((state) => state.cashback_type);
  const game = useGame();
  const navigate = useNavigate();
  const setPoints = useBonusesStore((state) => state.setPoints);
  const spentSeconds = useBonusesStore((state) => state.spent_seconds);
  const fakeBonusImagesCount = fakeBonusImages.slice();

  useEffect(() => {
    if (cashbackType !== null) {
      const filteredBonuses = cashbackData.filter(
        (bonus) => bonus.type === cashbackType
      );

      const fakeBonuses = cashbackData.filter(
        (bonus) => bonus.type !== cashbackType
      );

      const randomFakeBonusIndexes = getRandomIndexes(
        0,
        fakeBonuses.length - 1,
        2
      );

      if (randomFakeBonusIndexes.length > 0) {
        randomFakeBonusIndexes.push(randomFakeBonusIndexes[0]);
        setFakeBonusImages(
          randomFakeBonusIndexes.map(
            (index) => fakeBonuses[index].image_selected
          )
        );
      }

      if (filteredBonuses.length > 0) {
        setBonusImage(filteredBonuses[0].image_selected);
      }
    }

    document.addEventListener('contextmenu', preventDefaultFunction);

    return () => {
      document.removeEventListener('contextmenu', preventDefaultFunction);
    };
  }, [cashbackType]);

  useEffect(() => {
    game.listen(player, obstacles, resetObs, bonuses, resetBonus);
  }, [player, obstacles, bonuses, game, resetObs, resetBonus]);

  useEffect(() => {
    setPoints(game.score);
    if (game.isEnd === true || spentSeconds === 30) {
      navigate('/final');
    }

    // if (game.score === 10) {
    //   setTimeout(() => {
    //     navigate("/final");
    //   }, 1000);
    // }
  }, [game.isEnd, game.score, navigate, setPoints, spentSeconds]);

  useEffect(() => {
    if (player.x > obstacles[obstacles.length - 3].x) addObstacle();
    if (player.x > bonuses[bonuses.length - 3].x) addBonus();
  }, [player, obstacles, bonuses, addObstacle, addBonus]);

  useEffect(() => {
    player.run();
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp' || e.key === ' ') {
        if (!player.isRunning) player.run();
        else if (!player.isJump) player.jump();
      }

      if (e.key === 'ArrowRight') {
        if (!player.isRunning) player.run();
      }

      if (e.key === 'ArrowDown') {
        if (!player.isRunning) player.run();
        else if (!player.isSitting) player.sit();
      }
    };

    const handleClick = (evt) => {
      if (evt.target.classList.contains('arrow-button')) return;

      if (!player.isRunning) player.run();
      else if (!player.isJump) player.jump();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.body.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.removeEventListener('click', handleClick);
    };
  });

  const paddingLeft = 80;

  const onButtonUpClick = () => {
    if (!player.isRunning) player.run();
    else if (!player.isJump) player.jump();
  };

  const onButtonDownClick = () => {
    if (!player.isRunning) player.run();
    else if (!player.isSitting) player.sit();
  };

  const getObstacleHeight = (img) => {
    if (img === '/obstacles/cone.svg' || img === 'bird') return '163px';
    if (img === '/obstacles/cloud.svg') return '200px';
    return '70px';
  };

  const getObstacleWidth = (img) => {
    if (img === '/obstacles/cone.svg') return '120px';
    if (img === 'bird') return '160px';
    return '200px';
  };

  return (
    <>
      <div className="canvas">
        <div
          className="player"
          onClick={() => player.jump()}
          style={{
            backgroundImage: player.isSitting
              ? `url('/player/sit-player.svg')`
              : `url('/player/player.svg')`,
            bottom: player.y + 100,
            left: paddingLeft,
            height: player.height,
            width: player.width,
            opacity: player.opacity + '%',
          }}
        ></div>
        {obstacles.length > 0 &&
          obstacles.map((obs, key) =>
            obs.image === 'bird' ? (
              <Lottie
                loop
                animationData={BirdJson}
                play
                className="obstacle"
                key={key}
                style={{
                  bottom: obs.y,
                  left: 0,
                  transform: `translate3d(${
                    obs.x - player.x + paddingLeft
                  }px, 0, 0)`,
                  width: getObstacleWidth(obs.image),
                  height: getObstacleHeight(obs.image),
                }}
              />
            ) : (
              <div
                className="obstacle"
                key={key}
                style={{
                  bottom: obs.y,
                  left: 0,
                  transform: `translate3d(${
                    obs.x - player.x + paddingLeft
                  }px, 0, 0)`,
                  backgroundImage: `url(${obs.image})`,
                  width: getObstacleWidth(obs.image),
                  height: getObstacleHeight(obs.image),
                }}
              />
            )
          )}

        <div className="ground" />

        {bonuses.length > 0 &&
          bonuses.map((bonus, key) => {
            return (
              <div
                className={
                  key === game.hiddenBonusIndex ? 'bonus_animate' : 'bonus'
                }
                key={key}
                style={{
                  left: 0,
                  transform: `translate3d(${
                    bonus.x - player.x + paddingLeft
                  }px, 0, 0)`,
                  bottom: bonus.y,
                }}
              >
                <img
                  className="ingame__bonus__image"
                  src={bonus.isFake ? fakeBonusImagesCount.pop() : bonusImage}
                  alt="Бонус"
                />
              </div>
            );
          })}
        <InfoBar player={player} game={game} />
        <Background player_x={player.x} />
      </div>
      <div className="underground-wrapper">
        <div className="buttons">
          <Button className="up" onClick={onButtonUpClick} />
          <Button className="down" onClick={onButtonDownClick} />
        </div>
        <div className="underground"></div>
      </div>
    </>
  );
}

export default App;
