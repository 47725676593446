// import hatch from './assets/obstacles/hatch.svg'
// import puddle from './assets/obstacles/puddle.svg'
// import cone from './assets/obstacles/cone.svg'

const getObstacle = () => {
  const images = [
    '/obstacles/hatch.svg',
    '/obstacles/puddle.svg',
    '/obstacles/cone.svg',
    '/obstacles/puddle.svg',
    '/obstacles/puddle.svg',
    '/obstacles/cone.svg',
    '/obstacles/hatch.svg',
    '/obstacles/puddle.svg',
    '/obstacles/cone.svg',
    '/obstacles/puddle.svg',
    '/obstacles/cone.svg',
    '/obstacles/hatch.svg',
    '/obstacles/cone.svg',
  ];

  return images[Math.floor(Math.random() * 13)];
};

const getHighObstacle = () => {
  const images = ['/obstacles/cloud.svg', 'bird'];
  return images[Math.floor(Math.random() * 2)];
};

const createObstacle = (x, y, w, h) => {
  return {
    x,
    y,
    w,
    h,
    image: y > 130 ? getHighObstacle() : getObstacle(),
  };
};
export default createObstacle;
