import { create } from 'zustand';

export const useBonusesStore = create((set) => ({
  cashback_type: null,
  points: 0,
  selectedBonus: null,
  spent_seconds: null,

  setSelectedBonus: (bonus) =>
  set((state) => ({
    ...state,
    selectedBonus: bonus,
  })),

  setPoints: (points) =>
  set((state) => ({
    ...state,
    points: points,
  })),

  setCashbackType: (cashbackType) =>
  set((state) => ({
    ...state,
    cashback_type: cashbackType,
  })),

  setSpentSeconds: (spentSeconds) =>
    set((state) => ({
      ...state,
      spent_seconds: spentSeconds,
    })),

  resetStore: () =>
    set((state) => ({
      ...state,
      cashback_type: null,
      points: 0,
      selectedBonus: null,
      spent_seconds: null,
    })),
}));
