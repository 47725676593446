import "./App.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import BonusItem from "./components/BonusItem";
import cashbackData from "./cashback.json";
import "swiper/css";
import { useEffect, useState, useRef } from 'react';
import { useBonusesStore } from './store';

export default function App() {
  const [selectedBonusImage, setSelectedBonusImage] = useState(null);
  const preventDefaultFunction = (e) => e.preventDefault();
  const setCashbackType = useBonusesStore((state) => state.setCashbackType);
  const resetStore = useBonusesStore((state) => state.resetStore);
  const interval = useRef(null);
  const buttonLinkClasses = `button__link ${
    selectedBonusImage === null
      ? 'button__link_inactive'
      : 'button__link_active'
  }`;

  useEffect(() => {
    resetStore();
    if (selectedBonusImage !== null) {
      setCashbackType(cashbackData[selectedBonusImage].type);
    }

    const onLineHandle = () => {
      const results = sessionStorage.results
        ? JSON.parse(sessionStorage.results)
        : [];
      if (results.length > 0) {
        if (interval.current) return;

        interval.current = setInterval(async () => {
          if (results.length === 0) {
            clearInterval(interval.current);
            interval.current = null;
            return;
          }
          const item = results.pop();
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BACK_ENDPOINT}/game-sessions`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(item),
              }
            );
            const reponseJSON = await response.json();
            return reponseJSON;
          } catch (error) {
            results.push(item);
            console.log('error ', error);
          } finally {
            sessionStorage.setItem('results', JSON.stringify(results));
          }
        }, 1000);
      } else {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      }
    };

    const offLineHandle = () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
    window.addEventListener('online', onLineHandle);
    window.addEventListener('offline', offLineHandle);
    document.addEventListener('contextmenu', preventDefaultFunction);

    return () => {
      window.removeEventListener('online', onLineHandle);
      window.removeEventListener('offline', offLineHandle);
      document.removeEventListener('contextmenu', preventDefaultFunction);
    };
  }, [resetStore, selectedBonusImage, setCashbackType]);

  return (
    <div className="wrapper">
      <div className="main__items__wrapper">
        <img className="main__logo" src="/logos/main-logo.svg" alt="Т-лого" />
        <h1 className="main__title">
          Выберите
          <br />
          приз:
        </h1>
        <Swiper
          className="swiper__wrapper"
          spaceBetween={50}
          slidesPerView={2.9}
        >
          {cashbackData.map((bonus, key) => {
            return (
              <SwiperSlide key={key}>
                <BonusItem
                  title={bonus.title}
                  description={bonus.description}
                  src={
                    ((selectedBonusImage === null ||
                      selectedBonusImage !== key) &&
                      bonus.image) ||
                    (selectedBonusImage === key &&
                      cashbackData[selectedBonusImage].image_selected)
                  }
                  click={() => setSelectedBonusImage(key)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <Link className={buttonLinkClasses} to="/game">
          Старт
        </Link>
      </div>
    </div>
  );
}
